import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Grid from "@material-ui/core/Grid";
import Item from "../components/item";
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
const _ = require('lodash');


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  toolbar: theme.mixins.toolbar,
}));

const PodiumsPage = props => {
  const data = useStaticQuery(graphql`
      query {
          allImageSharp {
              edges {
                  node {
                      fluid(maxWidth: 700) {
                          aspectRatio
                          base64
                          originalImg
                          originalName
                          presentationHeight
                          presentationWidth
                          sizes
                          src
                          srcSetWebp
                          srcSet
                          srcWebp
                          tracedSVG
                      }
                  }
              }
          }
      }
  `)
  const { allImageSharp: { edges } } = data;
  const classes = useStyles();

  function getImage(name) {
    let image = "test";

    edges.forEach(edge => {
      if (name === edge.node.fluid.originalName) {
        image = edge.node.fluid;
      }
    });

    return image
  }
  //title, desc, id, category
  const items = [
    {
      title: "Crown series podium",
      desc: `Robust solid construction with all exposed edges polished
    Height 45" Width 27-1/2" Depth 18".

    The Top and Base are made with 3/4" Thick Clear Acrylic and Core made with 1/2"`,
      price: "$995",
      salePrice: "$825",
      imgUrl: getImage("crown.png"),
      id: 7474483,
      category: "pod",
    },
    {
      title: "Divinity podium",
      desc: `Contemporary design with large top surface
    Height 48" Width 32" Depth 20"

    The Top and Base are made with 3/4" Thick Clear Acrylic and Core made with 1/2"`,
      price: "$1105",
      salePrice: "$950",
      imgUrl: getImage("divinity.png"),
      id: 7474621,
      category: "pod",
    },
    {
      title: "Cornerstone podium",
      desc: `Elegant design with many polished surfaces creating a spectrum of reflections
    Height 45" Width 31" Depth 22".

     Lectern top made with 3/4" acrylic.
     *Logo, Shelf, Wheels & Mic are Available

     `,
      price: "$1175",
      salePrice: "$995",
      imgUrl: getImage("Cornerstone.png"),
      id: 7474664,
      category: "pod",
    },
    {
      title: "Trinity Oval Top",
      desc: `Unique oval design made from thick gauge acrylic.
    Height 45" Width 28" Depth 18".
    *Logo, Shelf, Wheels & Mic are Available

     `,
      price: "$995",
      salePrice: "$875",
      imgUrl: getImage("Trinity oval top.png"),
      id: 7474688,
      category: "pod",
    },
    {
      title: "Victory Podium",
      desc: `Appropriate for any venue. classical & robust design.
    Height 45" Width 28" Depth 18".

    The Top and Base are made with 3/4" Thick Clear Acrylic and Core made with 1/2".
    *Logo, Shelf, Wheels & Mic are Available

     `,
      price: "995",
      salePrice: "875",
      imgUrl: getImage("clear victory.png"),
      id: 7474714,
      category: "pod",
    },
    // add smoked victory
    {
      title: "Executive Podium",
      desc: `Fashionable for any layout or decor but simple in design.
    Height 45" Width 27-1/2" Depth 18". *Logo, Shelf, Wheels & Mic are Available

     `,
      price: "$885",
      salePrice: "$775",
      imgUrl: getImage("06 artisan.jpg"),
      id: 7474745,
      category: "pod",
    },
    {
      title: "Z Podium",
      desc: `Retro but elegant Z shaped podium.
    Height 46" Width 24" Depth 18". Made from 1" and 3/4" acrylic 
    *Logo, Shelf, Wheels & Mic are Available

     `,
      price: "$1380",
      salePrice: "$1280",
      imgUrl: getImage("z podium.png"),
      id: 7474783,
      category: "pod",
    },
    {
      title: "Petite Podium",
      desc: `All the clarity and appeal of our larger podiums but minimized in size
    Height 45" Width 18" Depth 14-1/4".

    *Logo, Shelf, Wheels & Mic are Available

    Note: Color Options Available

     `,
      price: "$368",
      salePrice: "$325",
      imgUrl: getImage("petite.png"),
      id: 7444890,
      category: "pod",
    },
    {
      title: "Breakdown Podium",
      desc: `Lightweight and very functional
    Some hardware assembly required. 5 minutes to assemble. Comes with shelf
    Height 45" Width 28" Depth 16-1/2".

    *Logo, Shelf, Wheels & Mic are Available

     `,
      price: "$348",
      salePrice: "$328",
      imgUrl: getImage("Breakdown.png"),
      id: 7474821,
      category: "pod",
    },
    {
      title: "Phoenix Podium",
      desc: `A judicious and solid construction has appeal and clarity in design
    Height 46" Width 31" Depth 22".

    Note: Logo and Shelf are extra.

    *Logo, Shelf, Wheels & Mic are Available

     `,
      price: "1175",
      salePrice: "995",
      imgUrl: getImage("10 phoenix.jpg"),
      id: 7474857,
      category: "pod",
    },
    {
      title: "Superior Podium",
      desc: `One of our larger pulpits with the faceted front and options for shelf and wings
    Height 46" Width 36" Depth 22".

    *Logo, Shelf, Wheels & Mic are Available

     `,
      price: "$1250",
      salePrice: "$1095",
      imgUrl: getImage("11 superior.jpg"),
      id: 7437490,
      category: "pod",
    },
    {
      title: "Imperial Podium",
      desc: `A concentrated angled assembly that shines with reflective light
    Height 46" Width 27" Depth 19 1/2".

    NOTE: Color Options Available

    *Logo, Shelf, Wheels & Mic are Available

    Note: Color Options Available

     `,
      price: "$1175",
      salePrice: "$1095",
      imgUrl: getImage("Imperial.png"),
      id: 7445168,
      category: "pod",
    },
    {
      title: "Continental Podium",
      desc: `Our lowest cost fully assembled full size lecturn
    Height 45" Width 27" Depth 18".

    *Logo, Shelf, Wheels & Mic are Available

     `,
      price: "",
      salePrice: "$660",
      imgUrl: getImage("Continental.png"),
      id: 7474878,
      category: "pod",
    },
    {
      title: "Chrome Podium",
      desc: `A contemporary design that incorporates Chrome with Acrylic that stands proud in many public and private settings.

    Free shelf

     `,
      price: "",
      salePrice: "$799",
      imgUrl: getImage("chrome.jpg"),
      id: "EU7NMJ5MFKRPQ",
      category: "pod",
    },
    // TODO: add chair including to the paypal side of things.
    // {
    //   title: "Majestic Podium",
    //   desc: `1' White acrylic top with 3/4"clear base. Patterned after Academy Awards presentation rostrum
    // Height 40" Width 38" Depth 26".
    //
    // *Logo, Shelf, Wheels & Mic are Available
    //
    //  `,
    //   price: "",
    //   salePrice: "$1475",
    //   imgUrl: getImage("15 majestic.jpg"),
    //   id: 7475195,
    //   category: "pod",
    // },
    {
      title: "Tithes Box",
      desc: `*Logo, Shelf, Wheels & Mic are Available

Color Options Available`,
      price: "",
      salePrice: "$575",
      imgUrl: getImage("23 tithe boxes.jpg"),
      id: 7475867,
      category: "pod",
    },
    {
      title: `48" Communion Table`,
      desc: `Timeless in design with angles and facets. Integral for all religious assemblies.
Height 38" Width 48" Depth 26".

NOTE: Price Includes Verse

*Logo, Shelf, Wheels & Mic are Available

 `,
      price: "$1250",
      salePrice: "$1075",
      imgUrl: getImage("48 communion table.jpg"),
      id: 7475123,
      category: "pod",
    },
    {
      title: `60" Communion Table`,
      desc: `Just a stretched version of the 48" Communion table
Height 38" Width 60" Depth 26".

NOTE: Price Includes Verse

*Logo, Shelf, Wheels & Mic are Available

 `,
      price: "$1450",
      salePrice: "$1275",
      imgUrl: getImage("60 communion table.jpg"),
      id: 7475157,
      category: "pod",
    },
//     {
//       title: "Majestic Communion Table",
//       desc: `1" white acrylic top with 3/4" clear acrylic base
// Height 38" Width 48" Depth 26".
//
// *Logo, Shelf, Wheels & Mic are Available
//
//  `,
//       price: "",
//       salePrice: "$1300",
//       imgUrl: getImage("20 majCommunion.jpg"),
//       id: 7475180,
//       category: "pod",
//     },
    {
      title: "Novus Acrylic Cleaner",
      desc: `NOVUS No. 1
Recommended Cleaner

Gently cleans all plastics without scratching. Leaves a lustrous shine that resists fogging, repels dust, and eliminates static`,
      price: "",
      salePrice: "$6",
      imgUrl: getImage("26 novN1.jpg"),
      id: 8256531,
      category: "pod",
    },
    {
      title: "Novus Acrylic Cleaner",
      desc: `NOVUS No. 2
Removes fine scratches, haziness and abrasions from most plastics. With repeated use, NOVUS No. 2 restores faded and discolored plastics.`,
      price: "",
      salePrice: "$7",
      imgUrl: getImage("27 novN2.jpg"),
      id: 8256577,
      category: "pod",
    },
    {
      title: "Novus Acrylic Cleaner",
      desc: `NOVUS No. 3
Removes heavy scratches and abrasions from most acrylic surfaces. Use when damage is too severe to be restored with NOVUS No. 2 polish.`,
      price: "",
      salePrice: "$8.50",
      imgUrl: getImage("28 novN3.jpg"),
      id: 78256611,
      category: "pod",
    },
    {
      title: "Novus Acrylic Cleaner Entire Set For: $25",
      desc: ``,
      price: "",
      salePrice: "$25",
      imgUrl: getImage("29 novSet.jpg"),
      id: 7476619,
      category: "pod",
    },
  ];

  return (
    <Layout>
    <div className={classes.root}>
    <CssBaseline />
      <SEO title="Products" />
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          {items.map(item => (
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <Item imgUrl={item.imgUrl} title={item.title} id={item.id} desc={item.desc} price={item.price} salePrice={item.salePrice} hidden={item.hidden}/>
            </Grid>
          ))}
        </Grid>
      </div>
    </Layout>
  )
}

export default PodiumsPage